.deleteAccountWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    margin: auto;
    width: 550px;
    text-align: center;
}

@media (max-width: 550px) {
    .deleteAccountWrapper {
        width: 300px;
    }
}