.container {
    margin-top: 50px;
    text-align: start;
}

.container p {
    margin: 0;
}

.container h2 {
    margin: 5px 0 0 0;
}

#personalData {
    display: inline-block;
    float: left;
}

#wineryData {
    display: inline-block;
    float: left;
    margin-left: 30px;
}

#selector {
    padding-top: 30px;
    clear: both;
    width: 200px;
    z-index: 200 !important;
}

.harvestTable,
.bottlingTable {
    margin-top: 30px;
}