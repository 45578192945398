.approveBtn {
  background-color: #ac9e66;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 1.3em;
  cursor: pointer;
  border: 3px solid #ac9e66;
  box-sizing: border-box;
  transition: all ease 0.3s;
}

.approveBtn:hover {
  border: 3px solid #ac9e66;
  background-color: white;
  box-sizing: border-box;
  color: #ac9e66;
  outline: none;
  transition: all ease 0.3s;
}

.wrapperUserInfo {
  margin-bottom: 50px;
}

.detailUserLabel {
  font-size: 1.3em;
  color: #ac9e66;
  margin-bottom: 20px;
}

.userDetailTitle {
  border-bottom: 2px solid #ac9e66;
  padding-bottom: 15px;
  color: #ac9e66;
}

.userValueTxt {
  background-color: whitesmoke;
  padding: 10px 30px;
  border-radius: 8px;
}
