.title {
    margin: 0;
    padding: 0;
    font-weight: 700;
}

.box {
    border: 1px solid #ac9e66;
    min-height: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px
}