.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.specialty{
  z-index: 1000 !important;
}

nav {
  width: 100%;
  height: 60px;
  background-color: black;
  color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  width: 100%;
  top: 0;
  z-index: 100;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tableWinegrowers {
  margin-top: 30px !important;
  width: 70%;
}

.winegrowersFilter {
  float: left;
  width: 87.5vw;
  background-color: #f8f6f6;
  border: 2px solid #dededf;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 5vh;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: #ac9e66  4px solid;
  opacity: 0;
  border-radius: 2%;
  animation: fadeUp 1.5s forwards;
  animation-delay: 0.5s;
}

.banner2 .login-container {
  animation: fadeDown 2s forwards !important;
  animation-delay: 0.5s;
}

.ui.card.login-card {
  width: 450px;
  background-color: black;
  border: none !important;
  box-shadow: none !important;
}

.login.button {
  float: right;
  background-color: #c5be9b !important;
}

.login.button:hover {
  background-color: #9c9681 !important;
}

.ui.form input[type='text'],
.ui.form input[type='password'],
.ui.form select {
  margin-top: 10px;
}

.batchesList {
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
  margin-top: 4rem;
  width: 80% !important;
}

.harvestTable {
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
  margin-top: 4rem;
}

.materialList {
  margin-top: 3rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 5px 15px;
}

.regsList {
  margin-top: 3rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableRow-root:hover {
  background-color: rgba(194, 182, 134, 0.219);
}

.MuiInput-underline:after {
  border-bottom: 2px solid rgb(0, 0, 0) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1e1e1e !important;
}

.MuiIconButton-colorSecondary {
  color: #1e1e1e !important;
}

.MuiTableBody-root a {
  text-decoration: none;
  color: black;
}

.MuiTableCell-alignLeft:nth-child(1) a {
  text-decoration: underline !important;
  color: gray !important;
}

.cellarlist .MuiTableCell-alignLeft:nth-child(2) a {
  text-decoration: underline !important;
  color: gray !important;
}

.MuiTableRow-footer {
  background-color: rgb(224, 222, 222) !important;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
}

a {
  color: black;
}

.Buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.configurationButtons .ReportingButtons .RegistrationButtons .AdminButtons .dashboardButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.col-lg-4.col-sm-6 a .single-service{
  display: block;
  height: 85%;
}

.dashboard-button {
  background-color: #ac9e66;
  color: white;
  border-radius: 5px;
  margin: 10px;
}

.dash-icon {
  height: 60px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-wrapper {
  text-align: center;
  padding-top: 15px;
  display: table;
}

.dashboard-button {
  height: 100px;
}

.dashButton_text {
  margin-top: 30px;
}

.nav {
  position: absolute !important;
  z-index: -5;
}

.nav_logo_ref {
  color: white;
}

.nav_logo_ref:hover {
  color: white;
}

.nav_buttons {
  float: right;
  position: relative;
  display: flex;
  margin-top: 10px;
}

.nav_buttons>div {
  display: inline-block;
}

.nav-button {
  background-color: transparent !important;
}

.nav-button-border {
  background-color: #bdb07f !important;
  border: 1px solid #d1c699c7 !important;
  border-radius: 10px !important;
  padding: 10px 15px !important;
}

.nav-button-border:hover {
  background-color: #f0e7c4c7 !important;
  border-radius: 10px !important;
  padding: 10px 15px !important;
}

.banner {
  opacity: 0.9;
  background-size: cover !important;
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 5rem 0;
  z-index: -1;
  background: linear-gradient(145deg,
      rgba(255, 255, 255, 0.85) 0%,
      rgba(255, 255, 255, 0.85) 100%),
    url('/src/components/images/winery.jpg') center no-repeat;
}

.banner2 {
  opacity: 0.85;
  background-size: cover !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 5rem 0;
  z-index: -1;
  background: linear-gradient(145deg,
      rgba(255, 255, 255, 0.85) 0%,
      rgba(255, 255, 255, 0.85) 100%),
    url('/src/components/images/winery.jpg') center no-repeat;
}

@media (max-width: 400px) {
  .banner {
    padding: 7rem 0 5rem 0 !important;
    background: none;
  }

  .banner2 {
    background: none;
  }
}

.banner::before {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  pointer-events: none;
}

.bannertext {
  padding: 10px 50px 20px 50px;
  color: #f8f8f8;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
  z-index: 2000;
}

@keyframes fadeUp {
  0% {
    transform: translateY(4rem);
  }

  100% {
    opacity: 0.95;
    transform: translateY(0rem);
  }
}

@keyframes fadeDown {
  0% {
    transform: translateY(-4rem);
  }

  100% {
    opacity: 0.95;
    transform: translateY(0rem);
  }
}

.login-content h2 {
  margin: 15px 0;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.login-content .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
}

.login-div {
  background-color: white;
  border: 1px solid whitesmoke;
  border-radius: 15px;
  padding: 2px 0 !important;
}

.login-content .input-div.one {
  margin-top: 0;
}

.i {
  color: #949494;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 5px;
}

.i i {
  transition: 0.3s;
}

.input-div>div {
  position: relative;
  height: 45px;
}

.input-div>div>h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}

.input-div>div>input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1rem;
  color: #555;
  font-family: 'poppins', sans-serif;
}

::placeholder {
  color: #555 !important;
}

.input-div.pass {
  margin-bottom: 4px;
}

input:focus::placeholder {
  color: transparent !important;
}

.actions {
  margin-top: 20px;
}

a:hover {
  text-decoration: none;
}

.newbatch {
  position: relative;
  z-index: 5;
  float: left;
  margin-left: 10.5%;
  margin-top: 12px;
}

.newspray {
  position: relative;
  z-index: 5;
  float: left;
  margin-left: 10.5%;
  margin-top: 12px;
}

.newvineyard {
  position: relative;
  z-index: 5;
  float: left;
  margin-left: 10.5%;
  margin-top: 35px;
}

.generateHarvestStockReport {
  position: relative;
  z-index: 1;
  float: left;
  margin-left: 15px;
  margin-right: 15px;
}

.dashboard-button:hover {
  background-color: #ac9e66db;
}

.hidden {
  display: none !important;
}

.show {
  display: unset !important;
}

.noCenter {
  text-align: left;
}

h2 {
  font-size: 1.5em;
}

.dbTitle {
  font-size: 24px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 30px;
  padding: 0 30px;
  color: white;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  font-weight: bold;
}

.dbTitle::after {
  content: '';
  background-color: black;
  width: 100%;
  height: 120%;
  position: absolute;
  top: 0%;
  left: 0;
  z-index: -1;
  transform: skew(-10deg);
}

.single-service {
  position: relative;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 4px;
  z-index: 1;
  overflow: hidden;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.15);
  background: black;
  animation: fadeInDb 1.5s forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.single-service .service-img {
  margin-bottom: 20px;
}

.single-service .service-img img {
  width: 70px;
}

.single-service .service-content h3 {
  color: white;
  margin-bottom: 5px;
  font-size: 23px;
}

.single-service .service-content p {
  margin-bottom: 10px;
  margin-top: 20px;
  color: white;
  padding-bottom: 20px;
}

.single-service::before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.795);
  z-index: -1;
  -webkit-transition: all 0.65s;
  transition: all 0.65s;
}

.single-service::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.795);
  z-index: -1;
  -webkit-transition: all 0.65s;
  transition: all 0.65s;
}

.single-service:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.single-service:hover h3 {
  color: #ac9e66;
}

.single-service:hover p {
  color: #ac9e66;
}

.single-service:hover .service-img img {
  filter: invert(0.2);
  animation: turnDb 2.7s infinite;
}

.single-service:hover::before {
  left: 0;
}

.single-service:hover::after {
  right: 0;
}

.single-service:hover .service-content .line-bnt {
  color: #ffffff;
}

@keyframes fadeInDb {
  0% {}

  100% {
    opacity: 0.98;
  }
}

@keyframes turnDb {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes fadeInLeftDb {
  0% {
    transform: translateX(-8rem);
  }

  100% {
    opacity: 0.4;
    transform: translateY(0rem);
  }
}

.ui.form input:not([type]),
.ui.form input[type='date']:focus,
.ui.form input[type='datetime-local']:focus,
.ui.form input[type='email']:focus,
.ui.form input[type='file']:focus,
.ui.form input[type='number']:focus,
.ui.form input[type='password']:focus,
.ui.form input[type='search']:focus,
.ui.form input[type='tel']:focus,
.ui.form input[type='text']:focus,
.ui.form input[type='time']:focus,
.ui.form input[type='url']:focus {
  border: 2px solid #ac9e66 !important;
}

.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'] {
  border: 1px solid #ac9e66 !important;
}

.ui.form select {
  border: 1px solid #ac9e66 !important;
}

.history-table .css-2b097c-container {
  z-index: 100 !important;
}

.css-b8ldur-Input input {
  opacity: 0 !important;
}

.css-ql68v7-control {
  height: 50px !important;
  border: 1px solid #ac9e66 !important;
}

.css-ql68v7-control:focus {
  height: 50px !important;
}

@media (max-width: 767px) {
  .nav-button {
    padding: 14px 10px !important;
  }

  .login-container {
    width: 120%;
    border-radius: 3%;
    margin-top: 30px !important;
    border: none !important;
  }

  .bannertext {
    max-width: 100vw;
  }

  .banner {
    height: 95vh !important;
  }

  .ui.card.login-card {
    border: 4px solid #ac9e66  !important;
  }

  .nav-button-border {
    background: none !important;
    border: none !important;
    font-size: 12px !important;
    padding: 0 5px !important;
  }

  .actions {
    text-align: inherit !important;
  }

  .input-div>div>input {
    font-size: 12.5px;
  }

  .single-service {
    min-height: 260px !important;
  }

  .user-button {
    display: none;
  }

  .ui.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .newbatch {
    margin-top: 18px !important;
    margin-left: 27% !important;
  }

  .newvineyard {
    margin-left: 22% !important;
    margin-top: 18px;
  }

  .newbatch>button {
    margin-top: -30px !important;
  }

  .newbatch>a>button,
  .newvineyard>a>button {
    margin-top: -30px !important;
  }

  .ui.stackable.grid {
    margin-top: 20px !important;
  }

  .button-vineyards {
    width: auto !important;
  }

  .check-location {
    margin-right: 22% !important;
  }

  .create-cellar {
    margin-top: 15px !important;
    margin-right: 31% !important;
  }

  .regs-form {
    width: 80%;
  }

  .regs-filter {
    justify-content: unset !important;
    margin-left: 10% !important;
  }

  .second-filter {
    margin-top: 70px !important;
    position: absolute !important;
    width: 90% !important;
    max-width: 100vw !important;
    right: 20px;
  }

  .second-filter>.fields>.field {
    width: 300px !important;
  }

  .filterList {
    margin-top: 220px !important;
  }

  .bttn-filterBatch {
    left: 100% !important;
  }

  .first-filter {
    right: 6% !important;
    width: 100% !important;
  }

  .bttn-filterRange {
    position: relative;
    left: 0 !important;
    top: 120px !important;
  }

  .bttn-profile {
    width: unset !important;
  }

  .psswrd-icon-2 {
    top: 54% !important;
  }

  .psswrd-icon-3 {
    top: 65.5% !important;
  }

  .psswrd-icon-4 {
    top: 83% !important;
  }

  .psswrd-icon-5 {
    top: 33.6% !important;
    right: 30px !important;
  }

  .psswrd-icon-6 {
    top: 38.6% !important;
  }
}

@media (min-width: 767px) and (max-width: 800px) {
  .login-container {
    border: none !important;
  }

  .ui.card.login-card {
    width: 75%;
    border: 4px solid #ac9e66  !important;
  }

  .bannertext {
    padding: 0 !important;
  }

  .actions {
    text-align: inherit !important;
  }

  .ui.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .regs-filter {
    justify-content: unset !important;
  }

  .second-filter {
    margin-top: 70px !important;
    position: absolute !important;
  }

  .filterList {
    margin-top: 15% !important;
  }

  .bttn-filterBatch {
    left: 95% !important;
  }

  .first-filter {
    right: 6% !important;
  }

  .bttn-profile {
    width: unset !important;
  }

  .first-filter {
    right: 6% !important;
    width: 100% !important;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .bannertext {
    padding: 0 !important;
  }

  .actions {
    text-align: inherit !important;
  }

  .ui.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .regs-filter {
    justify-content: unset !important;
  }

  .second-filter {
    margin-top: 70px !important;
    position: absolute !important;
  }

  .filterList {
    margin-top: 10% !important;
  }

  .bttn-filterBatch {
    left: 85% !important;
  }

  .first-filter {
    right: 8.5% !important;
  }

  .bttn-profile {
    width: unset !important;
  }
}

@media (hover: none) {
  .single-service::before {
    display: none;
  }

  .single-service::after {
    display: none;
  }
}

.psswrd-icon-1 {
  position: absolute;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  right: 10px;
  top: 12px;
  display: none;
}

.psswrd-icon-2 {
  position: absolute;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  right: 10px;
  top: 45.3%;
  display: none;
}

.psswrd-icon-3 {
  position: absolute;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  right: 10px;
  top: 58.3%;
  display: none;
}

.psswrd-icon-4 {
  position: absolute;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  right: 10px;
  top: 79%;
  right: 25px;
  display: none;
}

.psswrd-icon-5 {
  position: absolute;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  top: 34%;
  right: 52.5%;
  display: none;
}

.psswrd-icon-6 {
  position: absolute;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  top: 34%;
  right: 30px;
  display: none;
}

.psswrd-icon-7 {
  position: relative;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  left: 440px;
  top: 70px;
  display: none;
}

.psswrd-icon-8 {
  position: relative;
  z-index: 5;
  color: #ac9e66;
  font-size: 19.5px;
  left: 440px;
  top: 70px;
  display: none;
  height: 0px;
}

.icon-active {
  display: initial;
}

.selectDashboard .css-2b097c-container {
  z-index: 500 !important;
}

.grey {
  background-color: #888888 !important;
  display: unset;
}

.completeButton {
  cursor: pointer;
}

.ui.primary.button.login.completeFinish {
  float: unset !important;
  background-color: #ac9e66 !important;
}

th {
  background: #aca585 !important;
}

.MuiTableRow-footer {
  background: white !important;
}

ui.primary.button.login {
  margin: 0.25em;
}

.errorText {
  color: red;
  background-color: #ac9e66;
}

.errorMethod {
  color: red;
  font-weight: bold;
}

.MuiTableCell-paddingCheckbox {
  color: #aca585 !important;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

img {
  max-width: 100%;
}

img a {
  border: none;
}

ul {
  list-style-type: none;
}

select {
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #c5bb94;
  background-image: none;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select */
.select {
  position: absolute;
  display: flex;
  width: 90px;
  height: 2.5em;
  line-height: 2.5;
  overflow: hidden;
  border-radius: 0.25em;
  right: 0;
  top: 10px;
  margin-right: 16px;
  color: white;
  z-index: 1000;
}

select {
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}

/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #ac9e66 ;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

/* Transition */
.select:hover::after {
  color: #c5bb94;
}

.selectOnClick {
  transform: rotateX(180deg);
}

.reportSelect::-ms-expand {
  display: initial !important;
  -webkit-appearance: initial !important;
  -moz-appearance: initial !important;
  -ms-appearance: initial !important;
  appearance: initial !important;
}

.report-select .css-2b097c-container {
  z-index: 50;
  margin-top: 10px;
}

.report-select1 .css-2b097c-container {
  z-index: 1500 !important;
  margin-top: 10px;
}

.report-list .MuiTableCell-root {
  min-width: 200px;
}

.css-1g6g001 input {
  display: none !important;
}

.reportsection {
  background: #afa684 !important;
}

.display-regs div:nth-child(even) {
  background: #dbd4b8bd !important;
}

.db-popup {
  position: absolute;
  top: 80px;
  right: 10px;
  padding: 10px 20px;
  background: #b6a871ce;
  border-radius: 15px;
  color: white;
  font-weight: 700;
  display: flex;
  flex-direction: row;
}

.zIndex .css-2b097c-container {
  z-index: 200 !important;
}

.MuiSlider-thumb {
  background: #ac9e66 !important;
}

.MuiSlider-root {
  color: #afa684 !important;
}

.MuiSlider-markActive {
  background-color: #b6a871ce !important;
}

.backbutton {
  background-color: #c4b98c;
  color: white;
  border: none;
  transition: all ease 0.5s;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 7px;
  border: 3px solid #c4b98c;
  margin-bottom: 1rem;
}

.backbutton:hover {
  background-color: white;
  color: #b6a871ce;
  border: 3px solid #b6a871ce;
  transition: all ease 0.5s;
  cursor: pointer;
}

.backbutton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.backbutton span:after {
  content: '🢘';
  position: absolute;
  opacity: 0;
  top: 0;
  left: -20px;
  transition: 0.5s;
}

.backbutton:hover span {
  padding-left: 25px;
}

.backbutton:hover span:after {
  opacity: 1;
  left: 0;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home {
  background-color: #ac9e66;
  width: 75px;
  height: 75px;
  border: 3px solid #ac9e66;
  border-radius: 100%;
  position: fixed;
  right: 5%;
  bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  z-index: 10;
}

.home i {
  color: white;
  padding: 12px 16px;
  font-size: 40px;
  cursor: pointer;
  border: none;
  outline: none;
}

.home:hover {
  background-color: white;
  color: #b6a871ce;
  border: 3px solid #b6a871ce;
  transition: all ease 0.5s;
  cursor: pointer;
  outline: none;
}

.home i:hover {
  color: #b6a871ce;
  transition: all ease 0.5s;
  cursor: pointer;
}

.tile-melding {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  display: grid;
  place-items: center;
  color: #b6a871ce;
  font-size: 20px;
  font-weight: bold;
}

.single-service:hover .tile-melding {
  background-color: transparent;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #b6a871ce;
}

input:hover+.slider {
  box-shadow: 0 0 8px #b6a871ce;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.winery-block {
  display: grid;
  align-items: center;
  grid-template-columns: 80% 20%;
  max-width: 1028px;
  margin-top: 20px;
  border: #ac9e66 4px solid;
  min-height: 60px;
  border-radius: 20px;
}

.winery-info {
  display: grid;
  grid-template-columns: 30% 40% 30%;
}

.winery-accept-btn {
  height: 100%;
  display: grid;
  place-items: center;
}

@media only screen and (max-width: 800px) {
  .winery-block {
    grid-template-columns: 70% 30%;
    height: 120px;
  }

  .winery-info {
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.no-wineries {
  margin-top: 40px;
  font-weight: lighter;
  font-size: 32px;
  color: #ac9e66;
  text-decoration: underline;
}

.goToApp {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app_links img {
  width: 250px;
  height: 90px;
  border-bottom: 5px solid white;
}

.app_links img:hover {
  transition: all ease-in-out 0.25s;
  border-bottom: 5px solid#ac9e66;
  border-radius: 10%;
}

.grape_variety {
  align-items: center;
  justify-content: center;
  margin: 28px !important;
}

.grape_variety button {
  background-color: #c4b98c;
  color: white;
  border: none;
  transition: all ease 0.5s;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 7px;
  border: 3px solid #c4b98c;
  outline: none;
  cursor: pointer;
}

.flex_grape {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flex_grape button {
  height: fit-content;
}

.flex_error {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.flex_error_red {
  font-size: 20px;
  font-weight: bold;
  color: red;
}

.center {
  display: grid;
  align-items: center;
}

.completeWGField {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
  padding: 5px;
}

.completeWGField label {
  width: 75%;
}

.ui.form .field > label {
  color: #80795d !important;
}