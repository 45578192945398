.title {
  color: #7f7f7f;
}

.button_new_parcel {
  color: #7f7f7f !important;
  background-color: #c5be9b !important;  
}
  


.parcel_table {
  margin-top: 3rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
}


