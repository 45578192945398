#message {
    background: white;
    max-width: 360px;
    margin: 100px auto 16px;
    padding: 32px 24px 16px;
    border-radius: 3px;
    text-align: center;
}

#message h3 {
    color: #888;
    font-weight: normal;
    margin: 16px 0 12px;
}

#message h2 {
    color: #ac9e66;
    font-weight: bold;
    margin: 0;
}

#message h1 {
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 16px;
}

#message p {
    line-height: 140%;
    margin: 16px 0 24px;
    font-size: 14px;
}

#message a {
    display: block;
    text-align: center;
    background: #039be5;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding: 16px;
    border-radius: 4px;
}

#message,
#message a {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#load {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 13px;
}

@media (max-width: 600px) {

    body,
    #message {
        margin-top: 0;
        background: white;
        box-shadow: none;
    }
}