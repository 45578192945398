.tracebatch-content {
  text-align: start;
}

.tracebatch-content > .header {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.tracebatch-content > .header > div {
  padding: 0 10px;
}

.tracebatch-content > .date-filter {
  background-color: #c4b98c;
  color: white;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 7px;
  display: flex;
  width: fit-content;
  justify-content: space-between;
  margin-top: 20px;
  user-select: none;
}

.tracebatch-content > .date-filter > span {
  padding: 0 10px;
  font-weight: bold;
}

.tracebatch-content > .date-filter > input {
  outline: none;
  width: 180px;
  height: 32px;
}
.tracebatch-content > .date-filter > input:hover {
    cursor: pointer;
}
.tracebatch-content > .date-filter > input:focus {
  border: grey 3px solid;
  border-radius: 4px;
}

.tracebatch-content > .steps {
  margin-left: 60px;
  border-left: 6px dotted #c4b98c;
  padding: 0 40px;
  padding-top: 40px;
}

.tracebatch-content > .steps > div {
  border: 4px solid #c4b98c;
  border-radius: 10px;
  margin-bottom: 40px;
}

.count {
  background-color: white;
  color: #c4b98c;
  margin-left: 4px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: grid;
  place-items: center;
  font-size: 20px;
  font-weight: bold;
}

.tracebatch-content > .steps > div > div:nth-child(1) > div:nth-child(2) {
  padding-left: 20px;
}

.tracebatch-content > .steps > div > div:nth-child(2) {
  display: grid;
  grid-template-columns: auto fit-content(200px);
}

.tracebatch-content > .steps > div > div:nth-child(2) > div {
  width: 100%;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div:first-child {
  padding-left: 54px;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div:last-child {
  padding: 20px;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div:empty {
  display: none;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div > p {
  margin-bottom: 4px;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div > img {
  border-radius: 10px;
}
