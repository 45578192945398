.tracebatch-content {
    text-align: start;
}

.tracebatch-content > .header {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.tracebatch-content > .header > div {
    padding: 0 10px;
}

.tracebatch-content > .dropdown {
    background-color: #c4b98c;
    color: white;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 7px;
    width: 240px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    user-select: none;
}

.tracebatch-content > .dropdown:hover {
    background-color: #ac9e66db;
    cursor: pointer;
}

.tracebatch-content > .dropdown:hover > .arrow {
    font-weight: bold;
}

.tracebatch-content > .dropdown > .arrow-right {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
}

.tracebatch-content > .dropdown > .arrow-down {
    transform: rotate(90deg);
    transition: transform 0.2s linear;
}

.tracebatch-content > .steps {
    margin-left: 60px;
    border-left: 6px dotted #c4b98c;
    padding: 0 40px;
    padding-top: 40px;
}

.tracebatch-content > .steps > div {
    border: 4px solid #c4b98c;
    border-radius: 10px;
    margin-bottom: 40px;
}

.tracebatch-content > .steps > div > div:nth-child(1) {
    height: 40px;
    background-color: #c4b98c;
    color: white;
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: start;
    align-items: center;
}

.count {
    background-color: white;
    color: #c4b98c;
    margin-left: 4px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: grid;
    place-items: center;
    font-size: 20px;
    font-weight: bold;
}

.tracebatch-content > .steps > div > div:nth-child(1) > div:nth-child(2) {
    padding-left: 20px;
}

.tracebatch-content > .steps > div > div:nth-child(2) {
    display: grid;
    grid-template-columns: auto fit-content(200px);
}

.tracebatch-content > .steps > div > div:nth-child(2) > div {
    width: 100%;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div:first-child {
    padding-left: 54px;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div:last-child {
    padding: 20px;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div:empty {
    display: none;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div > p {
    margin-bottom: 4px;
}
.tracebatch-content > .steps > div > div:nth-child(2) > div > img {
    border-radius: 10px;
}

#vineyard-link {
    background-color: #c4b98c;
    display: flex;
    align-items: center;
}
#vineyard-link div {
    padding-left: 54px;
    color: white;
    font-size: large;
    font-weight: bold;
    text-decoration: underline;
}
#vineyard-link div:hover {
    color: grey;
    cursor: pointer;
    user-select: none;
}

.merge {
    width: fit-content;
}

.merge div:first-child {
    display: flex;
    justify-content: space-evenly;
}

.merge div:first-child span {
    padding: 0 10px;
}

.merge .bracket div:first-child {
    background-color: #c4b98c;
    height: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.merge .bracket div:first-child div {
    background-color: white;
    height: 10px;
    margin: 0 10px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.merge div:last-child {
    display: flex;
    justify-content: space-evenly;
}

.split {
    width: fit-content;
}

.split div:first-child {
    display: flex;
    justify-content: space-evenly;
}


.split .bracket div:first-child {
    background-color: #c4b98c;
    height: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.split .bracket div:first-child div {
    background-color: white;
    height: 10px;
    margin: 0 10px;
    margin-top: 10px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.split div:last-child {
    display: flex;
    justify-content: space-evenly;
}

.split div:last-child span {
    padding: 0 10px;
}